import React, { useState, useEffect, useRef } from "react";
import "./VenuePage.css";

export default function VenuePage() {
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);

  const addressClick = (event) => {
    window.open("https://maps.app.goo.gl/hJYeaPUQRrHFBzro8", "_blank");
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="venue-page" ref={containerRef}>
      <span className={isActive ? "location-text active" : "location-text"}>
        Location
      </span>
      <div className="address-container">
        <div className="phalosa-text-container">
          <span className={isActive ? "phalosa-text active" : "phalosa-text"}>
            PHALOSA VILLA
          </span>
          <span className={isActive ? "phalosa-text active" : "phalosa-text"}>
            BALI, INDONESIA
          </span>
        </div>
        <div className="schedule">
          <div
            className={isActive ? "holy-matrimony active" : "holy-matrimony"}
          >
            <span>HOLY MATRIMONY</span>
            <span>3.30 PM</span>
          </div>
          <div className={isActive ? "reception active" : "reception"}>
            <span>RECEPTION</span>
            <span>5.30 PM - TILL DROP</span>
          </div>
        </div>
        <div className="location-button">
          <button
            className={isActive ? "button-address active" : "button-address"}
            onClick={addressClick}
          >
            GO TO MAPS
          </button>
        </div>
      </div>
      <img
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1711213251/wed-cyn-cir/tree-left-top.png"
        className={isActive ? "tree-left top active" : "tree-left top"}
        alt="tree"
      />
      <img
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1711213257/wed-cyn-cir/tree-left-bottom.png"
        className={isActive ? "tree-left bottom active" : "tree-left bottom"}
        alt="tree"
      />
      <div className="fountain-and-trees">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952534/wed-cyn-cir/fountain-location.png"
          className={
            isActive ? "fountain-location active" : "fountain-location"
          }
          alt="fountain"
        />
      </div>
      <div className={isActive ? "tree-right active" : "tree-right"}>
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711213481/wed-cyn-cir/tree-right-top.png"
          className={isActive ? "tree-right-top active" : "tree-right-top"}
          alt="tree"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711213481/wed-cyn-cir/tree-left-mid.png"
          className={isActive ? "tree-right-mid active" : "tree-right-mid"}
          alt="tree"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711213480/wed-cyn-cir/tree-right-bottom.png"
          className={
            isActive ? "tree-right-bottom active" : "tree-right-bottom"
          }
          alt="tree"
        />
      </div>
    </div>
  );
}
