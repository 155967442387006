import React, { useState, useEffect, useRef } from "react";
import "./DatePage.css";

export default function DatePage() {
  const [isActive, setIsActive] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="date-page" ref={containerRef}>
      <div className="flower-border-top">
        <img
          className={isActive ? "blue-flower-left active" : "blue-flower-left"}
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1708846496/wed-cyn-cir/blue-flower-left.png"
          alt="flower"
        />
        <img
          className={
            isActive ? "blue-flower-right active" : "blue-flower-right"
          }
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/blue-flower-right"
          alt="flower"
        />
      </div>
      <div
        className={
          isActive
            ? "event-date-page-content active"
            : "event-date-page-content"
        }
      >
        <div className="flipper">
          <div className={isActive ? "flipper-inner active" : "flipper-inner"}>
            <div className="flipper-front"></div>
            <div className="flipper-back">
              <img
                className={isActive ? "logo active" : "logo"}
                src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952727/wed-cyn-cir/logo-cc.png"
                alt="logo"
              />
            </div>
          </div>
        </div>
        <div className="event-date-title">
          <span
            className={isActive ? "invitate-title active" : "invitate-title"}
          >
            TOGETHER WITH THEIR FAMILIES
          </span>
        </div>
        <div
          className={isActive ? "bride-groom-name active" : "bride-groom-name"}
        >
          <img alt="text" src="https://res.cloudinary.com/bottlecap23/image/upload/v1716906158/wed-cyn-cir/cyn-fullname_hmqr7t.png" className="bride-name" />
          <span className="name-separator">AND</span>
          <img alt="text" src="https://res.cloudinary.com/bottlecap23/image/upload/v1716906029/wed-cyn-cir/cyr-fullname_f1wty1.png" className="bride-name" />
        </div>

        <div className={isActive ? "on-text active" : "on-text"}>
          <span>INVITE YOU TO JOIN THEM</span>
          <span>IN THE CELEBRATION FOR MARRIAGE</span>
        </div>
      </div>
      <div className="fountain-and-flowers">
        <div className={isActive ? "flowers-behind active" : "flowers-behind"}>
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952461/wed-cyn-cir/fountain-flower-back.png"
            className={
              isActive ? "fountain-flower-back active" : "fountain-flower-back"
            }
            alt="flower"
          />
        </div>
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/fountain"
          className={isActive ? "fountain active" : "fountain"}
          alt="fountain"
        />
        <div
          className={isActive ? "flowers-front active" : "flowers-front"}
        ></div>
      </div>
    </div>
  );
}
