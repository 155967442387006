import './TextArea.css'
const TextArea = ({ id, label, sublabel, value, handler, error }) => {
  function inputHandler(e) {
    handler(e.target.value)
  }
  return (
    <div className={`form-group ${error && error !== '' ? 'error' : ''}`}>
      <label className="input-field-label" htmlFor={id}>
        {label}
      </label>
      <label className="input-field-sublabel" htmlFor={id}>
        {sublabel}
      </label>
      <textarea
        rows="4"
        className="text-area-field"
        id={id}
        value={value}
        onInput={inputHandler}
      ></textarea>
    </div>
  )
}

export default TextArea
