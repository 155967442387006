import React, { useEffect, useRef, useState } from 'react'
import './BrideAndGroomComponent.css'

export default function BrideAndGroomComponent(param) {
  const [isActive, setIsActive] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true)
        }
      },
      { threshold: 0.5 }
    )

    const currentRef = containerRef.current;
    
    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [])

  return (
    <div className="bride-and-groom-container" ref={containerRef}>
      <div className={`name-container ${param.type}`}>
        <span className={`name-text ${param.type} ${isActive ? 'active' : ''}`}>
          {param.name}
        </span>
        <div
          className={`name-detail  ${param.type}  ${isActive ? 'active' : ''}`}
          dangerouslySetInnerHTML={{ __html: param.nameDetail }}
        ></div>
      </div>
      <div className="bride-groom-photo">
        {param.photo && (
          <img
            className={`photo`}
            src={param.photo}
            alt="foto"
          />
        )}

        <img
          src='https://res.cloudinary.com/bottlecap23/image/upload/v1710951797/wed-cyn-cir/bride-groom-flower.png'
          className={
            isActive
              ? 'bride-groom-photo-flower active'
              : 'bride-groom-photo-flower'
          }
          alt="fountain"
        />
      </div>
      <div
        className={`bride-groom-title ${param.type} ${
          isActive ? 'active' : ''
        }`}
      >
        <div>THE</div>
        <div>{param.type}</div>
      </div>
    </div>
  )
}
