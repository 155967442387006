import React from "react";
import "./CurtainTransition.css";

function CurtainTransition() {
  return (
    <div className="curtain-container">
      <div className="curtain-panel-left-container">
        <img className="curtain-panel-left" src="https://res.cloudinary.com/dheb80jjm/image/upload/f_auto,q_auto/v1/wed-cyn-cyr-2/curtain_hkboo2" alt='cloud'/>
      </div>
      <div className="curtain-panel-right-container">
        <img className="curtain-panel-right" src="https://res.cloudinary.com/dheb80jjm/image/upload/f_auto,q_auto/v1/wed-cyn-cyr-2/curtain_hkboo2" alt='cloud'/>
      </div>
    </div>
  );
}

export default CurtainTransition;
