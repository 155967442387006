import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./ReservationPage.css";
import InputField from "./forms/InputField";
import RadioButton from "./forms/RadioButton";
import TextArea from "./forms/TextArea";
import Loader from "../../components/Loader/Loader";

export default function ReservationPage({
  guestName,
  guestPlusOneAllowed,
  guestRsvpnSubmitted,
  guestHonorific,
  setGuestRsvpSubmitted,
}) {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const [formData, setFormData] = useState({
    phone: "",
    initial: "",
    initial2: "",
    video: "",
    pax: null,
    is_attend: null,
  });
  const [errors, setErrors] = useState({});
  const { inviteeId } = useParams();

  const maxInitialLength = 3;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const renderAttending = () => {
    if (!guestPlusOneAllowed) {
      formData.pax = 1;
    }

    return (
      <>
        {guestPlusOneAllowed && (
          <RadioButton
            label="HOW MANY PERSON(S) WILL ATTEND?"
            sublabel="( including you )"
            name="pax"
            value={formData.pax}
            error={errors["pax"]}
            handler={(value) => {
              setErrors({});
              setFormData({
                ...formData,
                pax: value,
                initial: "",
                initial2: "",
                name2: "",
                dietary_restriction: "",
                phone: "",
              });
            }}
            options={[
              { value: 2, text: "I’m with my +1" },
              { value: 1, text: "It’s just me" },
            ]}
          />
        )}

        {formData.pax === 2 && renderAttendPlusOne()}
        {formData.pax === 1 && renderAttendAlone()}
      </>
    );
  };

  const renderNotAttending = () => {
    let tempGuestName = guestName;
    if (guestName.split("&").length > 1) {
      tempGuestName = guestName.split("&")[0];
    }
    return (
      <>
        <InputField
          value={tempGuestName}
          label="NAME"
          sublabel=""
          placeholder=""
          type="text"
          id="name"
          error=""
          isDisabled={true}
        />
        <InputField
          value={formData.video}
          label="FAR BY SIGHT BUT NEVER AT HEART,
          SEND YOUR WARMEST REGARDS TO CYNTHIA & CYRIL
          THROUGH A VIDEO MESSAGE HERE"
          sublabel="( upload your video here by sending us the link )"
          placeholder=""
          type="text"
          id="video"
          error=""
          handler={(value) => {
            setFormData({ ...formData, video: value });
          }}
        />
        {/* Textarea */}
        <TextArea
          label="SAY SOMETHING FOR OUR WEDDING"
          sublabel="( it would mean so much to us! )"
          error=""
          handler={(value) => {
            setFormData({ ...formData, wishes: value });
          }}
        />
      </>
    );
  };

  const renderForm = () => {
    return (
      <div className="form-container">
        {/* Radio */}
        <RadioButton
          label="WILL YOU BE ATTENDING?"
          name="is_attend"
          value={formData.is_attend}
          error={errors["is_attend"]}
          handler={(value) => {
            setErrors({});
            setFormData({
              ...formData,
              is_attend: value,
              pax: null,
              wishes: "",
              video: "",
              phone: "",
            });
          }}
          options={[
            { value: true, text: "Accept with pleasure" },
            { value: false, text: "Decline with regrets" },
          ]}
        />
        {formData.is_attend === true && renderAttending()}
        {formData.is_attend === false && renderNotAttending()}
        <div className="buttons-container">
          {Object.values(errors) &&
            Object.values(errors).filter((err) => err !== "").length !== 0 && (
              <div className="error-snackbar">
                make sure to fill all the required (<sup>*</sup>) questions
                above
              </div>
            )}
          {formData.is_attend !== null &&
            (formData.is_attend ? (
              formData.pax !== null && (
                <button onClick={submitForm} className="btn-reservation">
                  SEND NOW
                </button>
              )
            ) : (
              <button onClick={submitForm} className="btn-reservation">
                SEND NOW
              </button>
            ))}
          <button onClick={goToFAQPAge} className="btn-reservation faq">
            GO TO FAQ {">"} {">"}
          </button>
        </div>
      </div>
    );
  };

  const goToFAQPAge = () => {
    navigate(`/${inviteeId}/faq`);
  };

  const submitForm = async () => {
    setIsLoading(true);
    try {
      const apiUrl =
        process.env.REACT_APP_BOTTLECAP_API + "updateGuest/" + inviteeId;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Convert formData to JSON string
      };

      // Make API request
      const response = await fetch(apiUrl, options);
      const data = await response.json();
      if (data.errors && Object.keys(data.errors).length > 0) {
        setErrors(data.errors);
      } else {
        setGuestRsvpSubmitted(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const renderFinishForm = () => {
    let isDouble = false;
    if (guestName.split("&").length > 1) {
      isDouble = true;
    }
    return (
      <>
        <div className="finish-form-container">
          <div className="finish-form-name">
            Hi, {isDouble ? null : guestHonorific + "."} {guestName}
          </div>
          <div className="finish-form-info">
            YOUR RESPONSE HAS BEEN SUBMITTED
          </div>
          <div className="finish-form-info-detail">
            IF YOU WISH TO CHANGE THE DATA <br />
            YOU HAVE SUBMITTED, PLEASE CONTACT US
          </div>
        </div>
        <button
          onClick={() => window.open(process.env.REACT_APP_WA_ME_URL, "_blank")}
          className="btn-reservation btn-contact-us"
        >
          CONTACT US
        </button>
      </>
    );
  };

  const renderAttendPlusOne = () => {
    let tempGuestName = guestName;
    if (guestName.split("&").length > 1) {
      tempGuestName = guestName.split("&")[0];
    }
    return (
      <>
        <InputField
          value={tempGuestName}
          label="NAME"
          sublabel=""
          placeholder=""
          type="text"
          id="name"
          error=""
          isDisabled={true}
          handler={(value) => {
            return;
          }}
        />
        <InputField
          value={formData.name2}
          label="NAME OF YOUR PLUS ONE"
          sublabel=""
          placeholder=""
          type="text"
          id="name2"
          required="true"
          error={errors["name2"]}
          handler={(value) => {
            setFormData({ ...formData, name2: value });
            if (formData.name2 !== "" || formData.name2 !== null) {
              setErrors({ ...errors, name2: "" });
            }
          }}
        />
        <InputField
          value={formData.phone}
          label="PHONE NUMBER"
          sublabel=""
          placeholder=""
          type="number"
          id="name"
          required="true"
          error={errors["phone"]}
          handler={(value) => {
            setFormData({ ...formData, phone: value });
            if (formData.phone !== "" || formData.phone !== null) {
              setErrors({ ...errors, phone: "" });
            }
          }}
        />
        <div className="initial-container">
          <label>WE ARE PREPARING SOMETHING SPECIAL</label>
          <label className="input-field-label">INPUT YOUR INITIALS HERE</label>
          <label className="input-field-sublabel">
            ( for personalization purposes, max 3 letters )
          </label>
        </div>
        <div
          className="row"
          style={{ display: "flex", flexDirection: "row", gap: "32px" }}
        >
          <InputField
            style={{ width: "calc(50% - 16px)" }}
            value={formData.initial.replace(/[^A-Za-z]/g, "").toUpperCase()}
            label="YOUR INITIALS"
            sublabel=""
            placeholder=""
            type="text"
            id="initial"
            required="true"
            maxLength={maxInitialLength}
            error={errors["initial"]}
            handler={(value) => {
              setFormData({ ...formData, initial: value });
              if (formData.initial !== "" || formData.initial !== null) {
                setErrors({ ...errors, initial: "" });
              }
            }}
          />
          <InputField
            style={{ width: "calc(50% - 16px)", textWrap: "nowrap" }}
            value={formData.initial2.replace(/[^A-Za-z]/g, "").toUpperCase()}
            label="YOUR +1’s INITIALS"
            sublabel=""
            placeholder=""
            type="text"
            id="initial2"
            required="true"
            maxLength={maxInitialLength}
            error={errors["initial2"]}
            handler={(value) => {
              setFormData({ ...formData, initial2: value });
              if (formData.initial2 !== "" || formData.initial2 !== null) {
                setErrors({ ...errors, initial2: "" });
              }
            }}
          />
        </div>
        <InputField
          value={formData.dietary_restriction}
          label="ANY DIETARY RESTRICTIONS?"
          sublabel="( if no, just simply fill none )"
          placeholder=""
          type="text"
          id="dietary_restriction"
          error=""
          handler={(value) => {
            setFormData({ ...formData, dietary_restriction: value });
          }}
        />
        <InputField
          value={formData.dietary_restriction2}
          label="ANY DIETARY RESTRICTIONS FOR YOUR +1?"
          sublabel="( if no, just simply fill none )"
          placeholder=""
          type="text"
          id="dietary_restriction2"
          error=""
          handler={(value) => {
            setFormData({ ...formData, dietary_restriction2: value });
          }}
        />
        {/* Textarea */}
        <TextArea
          label="SAY SOMETHING FOR OUR WEDDING"
          sublabel="( it would mean so much to us! )"
          error=""
          handler={(value) => {
            setFormData({ ...formData, wishes: value });
          }}
        />
      </>
    );
  };

  const renderAttendAlone = () => {
    let tempGuestName = guestName;
    if (guestName.split("&").length > 1) {
      tempGuestName = guestName.split("&")[0];
    }
    return (
      <>
        <InputField
          value={tempGuestName}
          label="NAME"
          sublabel=""
          placeholder=""
          type="text"
          id="name"
          error=""
          isDisabled={true}
          handler={(value) => {
            return;
          }}
        />
        <InputField
          value={formData.phone}
          label="PHONE NUMBER"
          sublabel=""
          placeholder=""
          type="number"
          id="name"
          required="true"
          error={errors["phone"]}
          handler={(value) => {
            setFormData({ ...formData, phone: value });
            if (formData.phone !== "" || formData.phone !== null) {
              setErrors({ ...errors, phone: "" });
            }
          }}
        />
        <InputField
          value={formData.initial.replace(/[^A-Za-z]/g, "").toUpperCase()}
          label="YOUR INITIALS"
          sublabel="( for personalization purposes, max 3 letters )"
          placeholder=""
          type="text"
          id="initial"
          required="true"
          error={errors["initial"]}
          maxLength={maxInitialLength}
          handler={(value) => {
            if (formData.initial !== "" || formData.initial !== null) {
              setErrors({ ...errors, initial: "" });
            }
            setFormData({ ...formData, initial: value });
          }}
        />
        <InputField
          value={formData.dietary_restriction}
          label="ANY DIETARY RESTRICTIONS?"
          sublabel="( if no, just simply fill none )"
          placeholder=""
          type="text"
          id="dietary_restriction"
          error=""
          handler={(value) => {
            setFormData({ ...formData, dietary_restriction: value });
          }}
        />
        {/* Textarea */}
        <TextArea
          label="SAY SOMETHING FOR OUR WEDDING"
          sublabel="( it would mean so much to us! )"
          error=""
          handler={(value) => {
            setFormData({ ...formData, wishes: value });
          }}
        />
      </>
    );
  };

  return (
    <div className="reservation-container" ref={containerRef}>
      {isLoading && <Loader message={"Sending your RSVP..."} />}
      <span
        className={isActive ? "reservation-text active" : "reservation-text"}
      >
        Rsvp
      </span>
      <div className="description">
        <sup>*</sup>PLEASE KINDLY RSVP BEFORE SEPTEMBER 5TH 2024
      </div>
      {guestRsvpnSubmitted ? renderFinishForm() : renderForm()}
    </div>
  );
}
