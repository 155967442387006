import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import "./GalleryPage.css";

export default function GalleryPage() {
  const divStyle = {
    display: "inherit",
  };
  return (
    <ParallaxProvider>
      <Parallax>
        <div className="gallery-container">
          <img
            alt="foto"
            className="img-gallery"    
            src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899153/wed-cyn-cyr-2/gallery/Prewedd-0_v5mqmu.jpg"
          ></img>
          <div className="alt-text">CYNTHIA & CYRIL</div>
          <div className="header-wrapper">
            <Parallax
              speed={2}
              opacity={[0, 1, "easeInOut"]}
              translateX={[0, 0]}
            >
              <div className="h1">MELBOURNE, 12 AUGUST 2017</div>
            </Parallax>
            <div className="img-container mt-3">
              <div className="duo-image">
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[10, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery duo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899154/wed-cyn-cyr-2/gallery/Prewedd-1_mob1kc.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[10, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery duo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899154/wed-cyn-cyr-2/gallery/Prewedd-2_c1ljac.jpg"
                  />
                </Parallax>
              </div>
              <div className="duo-image">
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery duo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899156/wed-cyn-cyr-2/gallery/Prewedd-3_yha6ie.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery duo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899154/wed-cyn-cyr-2/gallery/Prewedd-4_jaojay.jpg"
                  />
                </Parallax>
              </div>
              <Parallax>
                <div className="mt-1 h2">
                  EVERY GREAT LOVE STARTS <br></br>WITH A GREAT STORY
                </div>
              </Parallax>
              <div style={divStyle} className="mt-2">
                <Parallax
                  speed={4}
                  opacity={[0, 1, "easeInOut"]}
                  translateX={[-30, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery solo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899155/wed-cyn-cyr-2/gallery/Prewedd-5_crf9wl.jpg"
                  />
                </Parallax>
                <div className="card-text-wrapper">
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[-30, 0]}
                  >
                    <div className="h3">
                      AND IN<br></br>THE END
                    </div>
                  </Parallax>
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[0, 0]}
                  >
                    <div className="h3">
                      I WILL SEEK<br></br>YOU OUT
                    </div>
                  </Parallax>
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[30, 0]}
                  >
                    <div className="h3">
                      AMONGST<br></br>THE STARS
                    </div>
                  </Parallax>
                </div>
              </div>
              <div style={divStyle} className="mt-3">
                <div className="card-text-wrapper">
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[-20, 0]}
                  >
                    <div className="h3">
                      THE<br></br>SPACE<br></br>DUST
                    </div>
                  </Parallax>
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[20, 0]}
                  >
                    <div className="h3">
                      OF ME<br></br>WILL<br></br>WHISPER
                    </div>
                  </Parallax>
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[20, 0]}
                  >
                    <div className="h3">
                      "I LOVE<br></br>YOU"
                    </div>
                  </Parallax>
                </div>
                <Parallax
                  speed={4}
                  opacity={[0.5, 1, "easeInOut"]}
                  translateX={[30, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery solo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899157/wed-cyn-cyr-2/gallery/Prewedd-6_uuwio3.jpg"
                  />
                </Parallax>
              </div>
              <div style={divStyle} className="mt-3">
                <Parallax
                  speed={4}
                  opacity={[0, 1, "easeInOut"]}
                  translateX={[-30, 0]}
                >
                  <img
                    alt="foto"
                    className="img-card-gallery solo-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899159/wed-cyn-cyr-2/gallery/Prewedd-7_xt41ts.jpg"
                  />
                </Parallax>
                <div className="card-text-wrapper">
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[-20, 0]}
                  >
                    <div className="h3">
                      INTO THE<br></br>INFINITY
                    </div>
                  </Parallax>
                  <Parallax
                    speed={4}
                    opacity={[0, 1, "easeInOut"]}
                    translateY={[20, 0]}
                  >
                    <div className="h3">
                      OF THE<br></br>UNIVERSE
                    </div>
                  </Parallax>
                </div>
              </div>
              <Parallax>
                <img
                  alt="foto"
                  className="img-card-gallery-3 mt-2"
                  src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899160/wed-cyn-cyr-2/gallery/Prewedd-8_w5uaty.jpg"
                ></img>
              </Parallax>
              <Parallax>
                <div className="mt-1 h2">CHEERS TO TWO IMPERFECT PIECES..</div>
              </Parallax>
              <div className="trio-image mt-1">
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899160/wed-cyn-cyr-2/gallery/Prewedd-9_tdp6ku.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899166/wed-cyn-cyr-2/gallery/Prewedd-10_ag4oxn.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899167/wed-cyn-cyr-2/gallery/Prewedd-11_crgmbc.jpg"
                  />
                </Parallax>
              </div>
              <Parallax>
                <div className="mt-1 h2">..THAT FITS PERFECTLY TOGETHER</div>
              </Parallax>
              <Parallax>
                <img
                  alt="foto"
                  className="img-card-gallery-3 mt-1"
                  src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899167/wed-cyn-cyr-2/gallery/Prewedd-12_yuzkq0.jpg"
                ></img>
              </Parallax>
            </div>
            <img
              alt="foto"
              className="img-gallery"
              src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899173/wed-cyn-cyr-2/gallery/Prewedd-13_rcxjeq.jpg"
            ></img>
            <div className="img-container mt-2">
              <div className="trio-image">
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899175/wed-cyn-cyr-2/gallery/Prewedd-14_nzyxyr.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899174/wed-cyn-cyr-2/gallery/Prewedd-15_zzjxmy.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899182/wed-cyn-cyr-2/gallery/Prewedd-16_pge16a.jpg"
                  />
                </Parallax>
              </div>
              <div className="trio-image">
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899183/wed-cyn-cyr-2/gallery/Prewedd-17_ps8xo5.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899184/wed-cyn-cyr-2/gallery/Prewedd-18_i7uzdu.jpg"
                  />
                </Parallax>
                <Parallax
                  speed={2}
                  opacity={[0, 1, "easeInOut"]}
                  translateY={[0, 0]}
                >
                  <img
                    alt="foto"
                    className="trio-card"
                    src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899187/wed-cyn-cyr-2/gallery/Prewedd-19_d2a45s.jpg"
                  />
                </Parallax>
              </div>
              <Parallax>
                <div className="mt-1 h2">OUR JOURNEY ISN'T PERFECT,</div>
              </Parallax>
              <Parallax>
                <div className="h2">BUT I PROMISE TO STICK WITH YOU</div>
              </Parallax>
              <Parallax>
                <div className="h2">TILL THE END.</div>
              </Parallax>
              <Parallax>
                <div className="mt-1 h2">YOU WILL ALWAYS BE, MY ALWAYS.</div>
              </Parallax>
            </div>
          </div>
          <img
            alt="foto"
            className="img-gallery mt-1 mb-2"
            src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899194/wed-cyn-cyr-2/gallery/Prewedd-20_yborkb.jpg"
          ></img>
          <div className="caption-container">
            <div className="title-text">
              HERE'S TO LIFE FULL OF<br></br>NEW ADVENTURES
            </div>
            <div className="alt-text-2">CYNTHIA & CYRIL</div>
          </div>
          <img
            alt="foto"
            className="img-gallery mb-2"
            src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899196/wed-cyn-cyr-2/gallery/Prewedd-21_eo39y4.jpg"
          ></img>
          <img
            alt="foto"
            className="img-gallery mb-2"
            src="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899196/wed-cyn-cyr-2/gallery/Prewedd-22_ltj11r.jpg"
          ></img>
        </div>
      </Parallax>
    </ParallaxProvider>
  );
}
