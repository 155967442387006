import React, { useState, useRef, useEffect } from "react";
import "./AudioPlayer.css";
import song from "../../assets/tenerife_sea.mp3";
import playIcon from "../../assets/images/music-button.png";
import pauseIcon from "../../assets/images/pause-button.png";

export default function AudioPlayer({ shouldPlay }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (shouldPlay && audioRef.current) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Autoplay failed:", error);
          });
      }
    }
  }, [shouldPlay]);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current
        .play()
        .then(() => setIsPlaying(true))
        .catch((error) => console.error("Failed to start playback:", error));
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleEnded = () => {
    audioRef.current.currentTime = 0;
    audioRef.current
      .play()
      .then(() => setIsPlaying(true))
      .catch((error) => console.error("Failed to start playback:", error));
  };

  return (
    <div className="audio-player">
      <audio
        id="audio"
        ref={audioRef}
        src={song}
        preload="auto"
        onEnded={handleEnded}
        autoPlay
      ></audio>
      <button
        onClick={handlePlayPause}
        className={isPlaying ? "play" : "pause"}
      >
        <img
          src={isPlaying ? pauseIcon : playIcon}
          alt="button"
          className="music-icon"
        />
      </button>
    </div>
  );
}
