import React, { useState, useEffect, useRef } from "react";

import "./InviteePage.css";

import CurtainTransition from "../../components/CurtainTransition/CurtainTransition";

export default function InviteePage(props) {
  const [isActive, setIsActive] = useState(false);
  const [isEnvelopeOpen, setEnvelopeOpen] = useState(false);
  const [isDouble, setDouble] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    if (props.inviteeName.split("&").length > 1) {
      setDouble(true);
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
          setEnvelopeOpen(true);
        } else {
          setIsActive(false);
          setEnvelopeOpen(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
    // eslint-disable-next-line
  }, []);

  const handleEnvelope = () => {
    setEnvelopeOpen(!isEnvelopeOpen);
  };

  return (
    <div className="invitee-page" ref={containerRef}>
      <CurtainTransition />
      <div className="envelope">
        <div className="invitee-page-content">
          <div className="verse-container">
            <span className={isActive ? "verse-title  active" : "verse-title"}>
              SONGS OF SOLOMON 3 : 4
            </span>
            <span className={isActive ? "verse-body active" : "verse-body"}>
              'I HAVE FOUND THE ONE WHOM MY SOUL LOVES'
            </span>
          </div>
        </div>
        <div className="envelope-back-container">
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952220/wed-cyn-cir/envelope-top.png"
            className={isEnvelopeOpen ? "envelope-top active" : "envelope-top"}
            alt="envelope"
          />
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952220/wed-cyn-cir/envelope-back.png"
            className="envelope-back"
            alt="envelope"
          />
        </div>
        <div
          className={
            isEnvelopeOpen ? "envelope-letter active" : "envelope-letter"
          }
        >
          <div className="envelope-text">
            <span className="dear-invitee-text">DEAR,</span>
            <span className="invitee-name">
              {isDouble ? null : props.inviteeHonorific + "."}{" "}
              {props.inviteeName}
            </span>
          </div>
        </div>
        <div className="envelope-front-container">
          <div className="seal-logo" onClick={handleEnvelope}>
            <img
              className={isActive ? "logo-seal active" : "logo-seal"}
              src="https://res.cloudinary.com/bottlecap23/image/upload/v1712482390/wed-cyn-cir/seal.png"
              alt="seal"
            />
          </div>
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952221/wed-cyn-cir/envelope-front.png"
            className="envelope-front"
            alt="envelope"
          />
        </div>
      </div>
    </div>
  );
}
