import { Route, BrowserRouter, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import "./App.css";

import EventPage from "./pages/EventPage/EventPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import FAQPage from "./pages/FAQPage/FAQPage";

function App() {
  const [isScreenOutOfBound, setIsScreenOutOfBound] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 || window.innerWidth <= 320) {
        setIsScreenOutOfBound(true);
      } else {
        setIsScreenOutOfBound(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    documentHeight();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };

  return (
    <BrowserRouter>
      <Routes>
        {!isScreenOutOfBound && (
          <>
            <Route path="/:inviteeId" element={<EventPage />} />
            <Route path="/:inviteeId/faq" element={<FAQPage />} />
          </>
        )}
        {isScreenOutOfBound && (
          <Route path="*" element={<NotFoundPage type="screen" />} />
        )}
        <Route path="/not-found" element={<NotFoundPage type="path" />} />
        <Route path="/" element={<NotFoundPage type="path" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
