import React, { useState, useEffect, useRef } from "react";
import "./DressCodePage.css";

export default function DressCodePage() {
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="dress-code-page" ref={containerRef}>
      <img
        className={
          isActive ? "ghost-flower right active" : "ghost-flower right"
        }
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952601/wed-cyn-cir/ghost-flower.png"
        alt="flower"
      />
      <div
        className={
          isActive
            ? "ghost-flower-left-container active"
            : "ghost-flower-left-container"
        }
      >
        <img
          className={
            isActive ? "ghost-flower left active" : "ghost-flower left"
          }
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952601/wed-cyn-cir/ghost-flower.png"
          alt="flower"
        />
      </div>

      <div className="dress-code-text-container">
        <span
          className={
            isActive ? "dress-code-text top active" : "dress-code-text top"
          }
        >
          Dress
        </span>
        <span
          className={
            isActive
              ? "dress-code-text bottom active"
              : "dress-code-text bottom"
          }
        >
          Code
        </span>
      </div>
      <div className="dress-code-content">
        <div className="dress-code-image">
          <div
            className={
              isActive ? "dress-men-container active" : "dress-men-container"
            }
          >
            <img
              className={isActive ? "dress-men active" : "dress-men"}
              src="https://res.cloudinary.com/bottlecap23/image/upload/v1710951907/wed-cyn-cir/dress-men.png"
              alt="dress"
            />
          </div>
          <div
            className={
              isActive
                ? "dress-women-container active"
                : "dress-women-container"
            }
          >
            <img
              className={isActive ? "dress-women active" : "dress-women"}
              src="https://res.cloudinary.com/bottlecap23/image/upload/v1710951909/wed-cyn-cir/dress-women.png"
              alt="dress"
            />
          </div>
        </div>
        <span className="attire-text">FORMAL ATTIRE</span>
        <div className="pallete">
          <div
            className={isActive ? "color-shape one active" : "color-shape one"}
          />
          <div
            className={isActive ? "color-shape two active" : "color-shape two"}
          />
          <div
            className={
              isActive ? "color-shape three active" : "color-shape three"
            }
          />
          <div
            className={
              isActive ? "color-shape four active" : "color-shape four"
            }
          />
        </div>
        <div className="pallete-text">
          <span className={isActive ? "pal-text one active" : "pal-text one"}>
            BLACK
          </span>
          <span className="straight-line">|</span>
          <span className={isActive ? "pal-text two active" : "pal-text two"}>
            NAVY
          </span>
          <span className="straight-line">|</span>
          <span
            className={isActive ? "pal-text three active" : "pal-text three"}
          >
            SAGE
          </span>
          <span className="straight-line">|</span>
          <span className={isActive ? "pal-text four active" : "pal-text four"}>
            PEACH
          </span>
        </div>
        <span className={isActive ? "no-white-text active" : "no-white-text"}>
          NO WHITE ALLOWED!
        </span>
      </div>
    </div>
  );
}
