import BrideAndGroomComponent from "./BrideAndGroomComponent";
import "./BrideAndGroomPage.css";
import { useEffect, useState, useRef } from "react";

export default function BrideAndGroomPage() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    let interval;

    if (!dragging) {
      interval = setInterval(() => {
        setCurrentSlide((current) => (current === 1 ? 0 : current + 1)); // Loop between 0 and 1
      }, 5000); // Change slide every 5 seconds (adjust as needed)
    } else {
      clearInterval(interval); // Clear the interval when dragging starts
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount or when dragging is active
  }, [dragging]);

  const handleDragStart = (e) => {
    setDragging(true);
    setDragStartX(getClientX(e));
  };

  const handleDragEnd = (e) => {
    if (!e) return; // Ensure the event object is valid
    setDragging(false);
    const dragEndX = getClientX(e);
    const difference = dragEndX - dragStartX;
    if (Math.abs(difference) > 100) {
      setCurrentSlide((current) =>
        difference > 0
          ? current === 0
            ? 1
            : current - 1
          : current === 1
          ? 0
          : current + 1
      );
    } else {
      sliderRef.current.style.transition = "transform 0.3s ease";
      sliderRef.current.style.transform = `translateX(-${currentSlide * 100}%)`;
    }
  };

  const handleDragMove = (e) => {
    if (!e) return; // Ensure the event object is valid
    if (dragging) {
      const dragCurrentX = getClientX(e);
      const difference = dragCurrentX - dragStartX;
      sliderRef.current.style.transition = "none";
      sliderRef.current.style.transform = `translateX(calc(-${
        currentSlide * 100
      }% + ${difference}px))`;
    }
  };

  const getClientX = (e) => {
    if (e.type.includes("touch")) {
      const touches = e.touches.length > 0 ? e.touches : e.changedTouches;
      return touches[0] ? touches[0].clientX : 0;
    } else {
      return e.clientX || 0; // Fallback to 0 if clientX is not available
    }
  };

  return (
    <div
      className={`bride-and-groom-slider ${dragging ? "dragging" : ""}`}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDragMove}
      onMouseLeave={handleDragEnd}
      onTouchStart={handleDragStart}
      onTouchEnd={handleDragEnd}
      onTouchMove={handleDragMove}
    >
      <div
        ref={sliderRef}
        className="slides"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
          transition: dragging ? "none" : "transform 0.75s ease",
        }}
      >
        <div className="bride-and-groom-slide">
          <BrideAndGroomComponent
            name="Cynthia"
            type="bride"
            photo="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899798/wed-cyn-cyr-2/bride-photo_ugzy49.jpg"
            nameDetail="<span style='font-size: 18px'>FIRST DAUGHTER OF</span><br />MR. SUMANTRI & <br />MRS. LYDIANA SANTOSO"
          />
        </div>
        <div className="bride-and-groom-slide">
          <BrideAndGroomComponent
            name="Cyril"
            type="groom"
            photo="https://res.cloudinary.com/dheb80jjm/image/upload/v1716899799/wed-cyn-cyr-2/groom-photo_qyl1om.jpg"
            nameDetail="<span style='font-size: 18px'>FIRST SON OF</span><br />
            MR. MARC GALLIGANI & <br />MRS. NATHALIE SIFFERT"
          />
        </div>
      </div>
    </div>
  );
}
