import './RadioButton.css'
const RadioButton = ({
  name,
  label,
  sublabel,
  value,
  handler,
  error,
  options = [{ value: '', text: '' }],
}) => {
  return (
    <div className={`form-group ${error && error !== '' ? 'error' : ''}`}>
      <label>{label}</label>
      <label className="input-field-sublabel">
        {sublabel}
      </label>
      <div className="radio-container">
        {options.map((option, index) => {
          return (
            <div className="options" key={'radio-' + name + index}>
              <input
                onInput={(e) => handler(option.value)}
                type="radio"
                name={name}
                value={option.value}
                id={`option-${name}-${index}-`}
              />
              <label htmlFor={`option--${name}-${index}`}>{option.text}</label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RadioButton
