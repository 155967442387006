import React, { useState, useEffect } from "react";
import "./LandingPage.css";

import CurtainTransition from "../../components/CurtainTransition/CurtainTransition";

export default function LandingPage({ setLandingClear }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);

    return () => {};
  }, []);

  function addDisappearClass(selector) {
    var elements = document.querySelectorAll(selector);
    elements.forEach(function (element) {
      element.classList.add("disappear");
    });
  }

  function beginJourney(e) {
    if (e) {
      e.preventDefault();
    }
    addDisappearClass(".seagull.left");
    addDisappearClass(".seagull.right");
    addDisappearClass(".seagull.right-bottom");
    addDisappearClass(".bottom-image");
    addDisappearClass(".headline");
    addDisappearClass(".wheel");
    addDisappearClass(".begin-text");
    addDisappearClass(".cloud-big");
    addDisappearClass(".cloud-small");
    addDisappearClass(".arrow-container");
    addDisappearClass(".curtain-panel-left-container");
    addDisappearClass(".curtain-panel-right-container");

    setTimeout(() => {
      window.scrollTo(0, 0);
      setLandingClear(true);
    }, 2000);
  }

  return (
    <div className="landing-page">
      <CurtainTransition />
      <div className="clouds">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1716827429/wed-cyn-cir/cloud-big.png"
          className="cloud-big"
          alt="cloud"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/cloud-small"
          className="cloud-small"
          alt="cloud"
        />
      </div>
      <img
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1708836160/wed-cyn-cir/seagull-right.svg"
        className={isActive ? "seagull left active" : "seagull left"}
        alt="seagull"
      />
      <img
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1708836160/wed-cyn-cir/seagull-right.svg"
        className={isActive ? "seagull right active" : "seagull right"}
        alt="seagull"
      />
      <img
        src="https://res.cloudinary.com/bottlecap23/image/upload/v1716833597/wed-cyn-cir/seagull-left.png"
        className={
          isActive ? "seagull right-bottom active" : "seagull right-bottom"
        }
        alt="seagull"
      />
      <div className="headline">
        <span className={isActive ? "text-journey active" : "text-journey"}>
          THE JOURNEY OF
        </span>
        <div
          className={isActive ? "title-container active" : "title-container"}
        >
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1716905896/wed-cyn-cir/cyn-cyr-font.png"
            className={isActive ? "title-image active" : "title-image"}
            alt="Cynthia & Cyril"
          />
        </div>
      </div>
      <div className="bottom-image-container">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1716907365/wed-cyn-cir/landing-photo.png"
          className={isActive ? "bottom-image active" : "bottom-image"}
          alt="cloud"
        />
      </div>
      <div
        className={isActive ? "footer active" : "footer"}
        onClick={beginJourney}
      >
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1716827109/wed-cyn-cir/wheel.png"
          className={isActive ? "wheel active" : "wheel"}
          alt="wheel"
        />
        <span className={isActive ? "begin-text active" : "begin-text"}>
          OPEN INVITATION
        </span>
      </div>
    </div>
  );
}
