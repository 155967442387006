import "./Loader.css";

export default function Loader({message}) {
  return (
    <div className="loader-overlay">
      <div className="loader"></div>
      <div>{message}</div>
    </div>
  );
}
