import React from "react";
import "./NotFoundPage.css";

export default function NotFoundPage({ type }) {
  return (
    <div className="not-found-page">
      <div className="clouds-not-found">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1716827429/wed-cyn-cir/cloud-big.png"
          className="cloud-left"
          alt="cloud"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/cloud-small"
          className="cloud-right"
          alt="cloud"
        />
      </div>
      <div className="headline-not-found">
        <div className="headline-title-not-found">
          <div className="headline-title-not-found-top">
            <div className="title-1">Dear Our</div>
            <img
              src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/seagull-right"
              className="seagull-right"
              alt="seagull"
            />
          </div>
          <div className="headline-title-not-found-bot">
            <div className="title-2">Loved Ones,</div>
          </div>
        </div>
        <div className="not-found-text-container">
          {type === "path" ? (
            <>
              <div className="not-found-text">SORRY, WE CAN'T FIND</div>
              <div className="not-found-text">THE PAGE YOU ARE LOOKING FOR</div>
            </>
          ) : (
            <>
              <div className="not-found-text">
                PLEASE REFER TO YOUR MOBILE BROWSER
              </div>
              <div className="not-found-text">TO OPEN THE INVITATION</div>
            </>
          )}
        </div>
        {type === "path" && (
          <button
            onClick={() =>
              window.open(process.env.REACT_APP_WA_ME_URL, "_blank")
            }
            className="btn-reservation btn-contact-us"
          >
            CONTACT US
          </button>
        )}
        <div className="not-found-footer">
          <img
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1716833597/wed-cyn-cir/seagull-left.png"
            className="seagull-left"
            alt="seagull"
          />
        </div>
      </div>

      <div className="not-found-logo">
        <span className="title-text">Cynthia</span>
        <span className="title-text">&</span>
        <span className="title-text">Cyril</span>
      </div>
      <div className="balloons">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/balloon_dvqkqy"
          className={"balloon-left"}
          alt="balloon"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/f_auto,q_auto/v1/wed-cyn-cir/balloon_dvqkqy"
          className={"balloon-right"}
          alt="balloon"
        />
      </div>
      <div className="cloud-bottom-container-not-found">
        <img
          src="https://res.cloudinary.com/dheb80jjm/image/upload/f_auto,q_auto/v1/wed-cyn-cyr-2/cloud-bottom"
          className={"cloud-bottom-not-found"}
          alt="cloud"
        />
      </div>
    </div>
  );
}
