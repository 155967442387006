import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./FAQPage.css";

export default function FAQPage() {
  const navigate = useNavigate();
  const { inviteeId } = useParams();

  const togglePanel = (id) => {
    const element = document.getElementById(id);
    const arrow = document.getElementById("arrow-" + id);
    if (element) {
      element.classList.toggle("open");
      arrow.classList.toggle("open");
    }
  };

  const goBack = () => {
    navigate(`/${inviteeId}`, { state: { prevPage: "faq" } });
  };

  return (
    <div className="faq-page">
      <div className="faq-title">FAQ</div>
      <div className="faq-accordion">
        <div className="panel-header" onClick={() => togglePanel("section1")}>
          <div>WHEN SHOULD I RSVP?</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section1"
          />
        </div>
        <div id="section1" className="panel">
          <p className="panel-para">
            The last day to RSVP is June 30, 2024. If we do not get an RSVP back
            by the date provided, it will be marked as a “No”. We will miss you
            celebrating with us, however, we have to provide total guest counts
            to our vendors in timely manner. Due to this we cannot accept late
            RSVPs, we hope that you understand!
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section2")}>
          <div>CAN I BRING A DATE OR +1?</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section2"
          />
        </div>
        <div id="section2" className="panel">
          <p className="panel-para">
            We are only able to accommodate those guests formally invited on
            your wedding invitation. If you received a plus one they will appear
            under your name when you RSVP. Thank you for understanding!
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section3")}>
          <div>ARE KIDS INVITED?</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section3"
          />
        </div>
        <div id="section3" className="panel">
          <p className="panel-para">
            Invitations will be specific and will include the names of the
            children who are formally invited. We hope you understand & can
            enjoy a fun date night!
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section4")}>
          <div>WHERE SHOULD I STAY</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section4"
          />
        </div>
        <div id="section4" className="panel">
          <p className="panel-para">
            Our venue is located in Seminyak, Bali. We would love to recommend
            you to stay around Seminyak as it is one of the famous tourist
            destination in Bali and it is such a convenient location to be
            around from our venue.
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section5")}>
          <div>WHAT TIME SHOULD I ARRIVE AT THE CEREMONY?</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section5"
          />
        </div>
        <div id="section5" className="panel">
          <p className="panel-para">
            We recommend that you arrive at least 20-30 minutes before our
            ceremony time. This gives us enough time to be on schedule. If you
            are late and the wedding has started, we ask you to stand in the
            back until the ceremony is finished and then join us at the
            reception!
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section6")}>
          <div>WHAT SHOULD I WEAR</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section6"
          />
        </div>
        <div id="section6" className="panel">
          <p className="panel-para">
            We would love to see our family and friends dress up with us! This
            event is a <b>black tie</b> event. Please refer to the colour
            palette provided on the dress code page. Patterns and White is
            strictly prohibited. Tuxedos and mid to floor length gowns are
            preferred. Feel free to reach out with any questions!
          </p>
        </div>
        <div className="panel-header" onClick={() => togglePanel("section7")}>
          <div>ARE THERE ANY COLORS THAT SHOULD BE AVOIDED?</div>
          <img
            alt="arrow"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712490387/wed-cyn-cir/arrow.png"
            className="arrow-faq"
            id="arrow-section7"
          />
        </div>
        <div id="section7" className="panel">
          <p className="panel-para">
            We kindly ask that you avoid colors traditional reserved for the
            bride - <b>white, ivories, and creams.</b>
          </p>
        </div>
      </div>
      <button className="button-faq-back" onClick={goBack}>
        BACK
      </button>
    </div>
  );
}
