import React, { useState, useEffect, useRef } from "react";
import "./CountDownPage.css";

export default function CountDownPage() {
  const [isActive, setIsActive] = useState(false);
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const containerRef = useRef(null);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const time = Math.floor(progress * (end - start) + start);
      obj.innerHTML = time < 10 ? "0" + time : time;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  function getTimeRemaining(countdownDate) {
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      distance: distance,
    };
  }

  useEffect(() => {
    const countdownDate = new Date("2024-10-05T04:30:00").getTime();
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
          const time = getTimeRemaining(countdownDate);
          const day = document.getElementById("days");
          const hour = document.getElementById("hours");
          const minute = document.getElementById("minutes");
          animateValue(day, 0, time.days, 3000);
          animateValue(hour, 0, time.hours, 3000);
          animateValue(minute, 0, time.minutes, 3000);
        } else {
          setIsActive(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    const countdownInterval = setInterval(() => {
      let countDown = getTimeRemaining(countdownDate);

      setCountdown({
        days: countDown.days < 10 ? "0" + countDown.days : countDown.days,
        hours: countDown.hours < 10 ? "0" + countDown.hours : countDown.hours,
        minutes:
          countDown.minutes < 10 ? "0" + countDown.minutes : countDown.minutes,
        seconds:
          countDown.seconds < 10 ? "0" + countDown.seconds : countDown.seconds,
      });

      if (countDown.distance < 0) {
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="countdown-page" ref={containerRef}>
      <div className="date-separator">
        <div className="on-date">
          <span>ON</span>
          <span>SATURDAY THE FIFTH OF</span>
        </div>
        <span className="october">OCTOBER</span>
        <span>TWO THOUSAND AND TWENTY FOUR</span>
      </div>
      <div className="countdown">
        <div className="time-container">
          <span className="time" id="days">
            {countdown.days}
          </span>
          <span>DAYS</span>
        </div>
        <div className="time-container">
          <span className="time" id="hours">
            {countdown.hours}
          </span>
          <span>HOURS</span>
        </div>
        <div className="time-container">
          <span className="time" id="minutes">
            {countdown.minutes}
          </span>
          <span>MINUTES</span>
        </div>
        <div className="time-container">
          <span className="time" id="seconds">
            {countdown.seconds}
          </span>
          <span>SECONDS</span>
        </div>
      </div>
      <div>
        <img
          alt="flower"
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1712482174/wed-cyn-cir/countdown-flower.png"
          className={
            isActive ? "count-flower right active" : "count-flower right"
          }
        />
        <div
          className={
            isActive
              ? "count-flower-container-left active"
              : "count-flower-container-left"
          }
        >
          <img
            alt="flower"
            src="https://res.cloudinary.com/bottlecap23/image/upload/v1712482174/wed-cyn-cir/countdown-flower.png"
            className="count-flower left"
          />
        </div>
      </div>
    </div>
  );
}
