import React, { useState, useEffect, useRef } from "react";
import "./GiftPage.css";

import barcodeIdr from "../../assets/images/barcode-idr.png";

export default function GiftPage() {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [idrClicked, setIdrClicked] = useState(false);
  const [audClicked, setAudClicked] = useState(false);
  const [isCopyFailed, setIsCopyFailed] = useState(false);

  const openModal = (content) => {
    if (content === "idr") {
      setIdrClicked(true);
      setAudClicked(false);
      setShowTooltip(false);
      setIsOpen(true);
    } else if (content === "aud") {
      setIdrClicked(false);
      setAudClicked(true);
      setShowTooltip(false);
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setShowTooltip(false);
    setIsOpen(false);
  };

  const copyAccountNumber = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setIsCopyFailed(false);
        setShowTooltip(true);
      })
      .catch((err) => {
        setIsCopyFailed(true);
      });
  };
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsActive(true);
          setShowTooltip(false);
          setIsOpen(false);
        } else {
          setIsActive(false);
          setShowTooltip(false);
          setIsOpen(false);
        }
      },
      { threshold: 0.5 }
    );

    const currentRef = containerRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div className="gift-page" ref={containerRef}>
      <div className="wishing-well-text-container">
        <span
          className={
            isActive ? "wishing-well-text top active" : "wishing-well-text top"
          }
        >
          Wishing
        </span>
        <span
          className={
            isActive
              ? "wishing-well-text bottom active"
              : "wishing-well-text bottom"
          }
        >
          Well
        </span>
      </div>
      <div className="gift-page-content">
        <div className={isActive ? "gift-text top active" : "gift-text top"}>
          <span>The most important gifts to us is</span>
          <span>having you share our special day.</span>
        </div>
        <div className={isActive ? "gift-text bottom active" : "gift-text top"}>
          <span>But if you wish to give</span>
          <span>in some other way,</span>
          <span>we would love you contribute for our </span>
          <span>honeymoon trip after tying the knot.</span>
        </div>
        <div className="gift-button">
          <button
            onClick={() => openModal("idr")}
            className={
              isActive ? "money idr-button active" : "money idr-button"
            }
          >
            IDR
          </button>
          <button
            onClick={() => openModal("aud")}
            className={
              isActive ? "money aud-button active" : "money aud-button"
            }
          >
            AUD
          </button>
        </div>
      </div>
      <div className="briefcases">
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711563323/wed-cyn-cir/briefcase-top.png"
          className={isActive ? "briefcase-top active" : "briefcase-top"}
          alt="briefcase"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711563323/wed-cyn-cir/briefcase-middle.png"
          className={isActive ? "briefcase-middle active" : "briefcase-middle"}
          alt="briefcase"
        />
        <img
          src="https://res.cloudinary.com/bottlecap23/image/upload/v1711563323/wed-cyn-cir/briefcase-bottom.png"
          className={isActive ? "briefcase-bottom active" : "briefcase-bottom"}
          alt="briefcase"
        />
      </div>
      {isOpen && idrClicked && (
        <div className="custom-overlay">
          <div className="custom-modal">
            <div className="modal-header">
              <span className="currency">IDR</span>
              <span onClick={closeModal} className="close-button">
                X
              </span>
            </div>
            <div className="modal-content">
              <img src={barcodeIdr} className="barcode-idr" alt="barcode"/>
              <span>BCA</span>
              <span className="account-number">497 107 5907</span>
              <span>Cynthia Samantha Sumali</span>
              <button
                id="copy"
                onClick={() => copyAccountNumber("4971075907")}
                className="copy-button"
              >
                COPY ACCOUNT NUMBER
              </button>
              {showTooltip && (
                <div className={isCopyFailed ? "tooltip failed" : "tooltip"}>
                  {" "}
                  {isCopyFailed ? "failed to copy" : "account number copied"}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isOpen && audClicked && (
        <div className="custom-overlay">
          <div className="custom-modal">
            <div className="modal-header">
              <span className="currency">AUD</span>
              <span onClick={closeModal} className="close-button">
                X
              </span>
            </div>
            <div className="modal-content aud">
              <h3>Cyril Julien Quentin Galligani</h3>
              <span>bsb</span>
              <span className="account-number">063-133</span>
              <span>account number</span>
              <span className="account-number">1149 0788</span>
              <button
                id="copy"
                onClick={() => copyAccountNumber("11490788")}
                className="copy-button"
              >
                COPY ACCOUNT NUMBER
              </button>
              {showTooltip && (
                <div className={isCopyFailed ? "tooltip failed" : "tooltip"}>
                  {" "}
                  {isCopyFailed ? "failed to copy" : "account number copied"}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
