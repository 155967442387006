import "./InputField.css";

const InputField = ({
  label,
  sublabel,
  value,
  placeholder,
  type,
  id,
  error,
  handler,
  style,
  maxLength,
  isDisabled = false,
  required,
}) => {
  function inputHandler(e) {
    handler(e.target.value);
  }
  return (
    <div
      className={`form-group ${error && error !== "" ? "error" : ""}`}
      style={style}
    >
      <label className="input-field-label" htmlFor={id}>
        {label}
        {required && <sup>*</sup>}
      </label>
      <label className="input-field-sublabel" htmlFor={id}>
        {sublabel}
      </label>
      <input
        type={type}
        className="input-field"
        id={id}
        placeholder={placeholder}
        value={value}
        onInput={inputHandler}
        maxLength={maxLength}
        disabled={isDisabled ? "disabled" : ""}
      />
    </div>
  );
};

export default InputField;
