import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import "./EventPage.css";

import LandingPage from "../LandingPage/LandingPage";
import VenuePage from "../VenuePage/VenuePage";
import BrideAndGroomPage from "../BrideAndGroomPage/BrideAndGroomPage";
import GalleryPage from "../GalleryPage/GalleryPage";
import DatePage from "../DatePage/DatePage";
import DressCodePage from "../DressCodePage/DressCodePage";
import InviteePage from "../InviteePage/InviteePage";
import ReservationPage from "../ReservationPage/ReservationPage";
import GiftPage from "../GiftPage/GiftPage";
import CountDownPage from "../CountDownPage/CountDownPage";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer"

export default function EventPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isLandingClear, setLandingClear] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [guestHonorific, setGuestHonorific] = useState("");
  const [guestRsvpnSubmitted, setGuestRsvpSubmitted] = useState("");
  const [guestPlusOneAllowed, setGuestPlusOneAllowed] = useState("");

  const { inviteeId } = useParams();

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_BOTTLECAP_API + "getGuest/";

    const checkOnTheList = (apiUrl) => {
      fetch(apiUrl + inviteeId, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) throw new Error(response.statusText);
          else return response.json();
        })
        .then((respData) => {
          const guestNameUpper = respData.full_name
            .replace(/-/g, " ")
            .toUpperCase();
          const guestHonorific = respData.honorific
            .replace(/-/g, " ")
            .toUpperCase();
          setGuestName(guestNameUpper);
          setGuestHonorific(guestHonorific);
          setGuestPlusOneAllowed(respData.is_plus_one_allowed);
          setGuestRsvpSubmitted(respData.is_rsvp_submitted);
        })
        .catch((error) => {
          navigate("/not-found");
        });
    };

    checkOnTheList(apiUrl);

    if (state && state.prevPage === "faq") {
      setLandingClear(true);
      setTimeout(() => {
        const divToScrollTo = document.querySelector(".reservation-container");
        if (divToScrollTo) {
          divToScrollTo.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [inviteeId, navigate, state]);

  const handleLandingClear = () => {
    setLandingClear(true);
  };

  return (
    <div className="main">
      {!isLandingClear && <LandingPage setLandingClear={handleLandingClear} />}
      {isLandingClear && (
        <>
          <InviteePage
            inviteeName={guestName}
            inviteeHonorific={guestHonorific}
          />
          <div className="flower-border-container">
            <img
              src="https://res.cloudinary.com/bottlecap23/image/upload/v1710952394/wed-cyn-cir/flowers-bq-bottom.png"
              className="flower-border"
              alt="flower"
            />
          </div>
          <DatePage />
          <div className="flower-front-container">
            <img
              src="https://res.cloudinary.com/bottlecap23/image/upload/v1711209783/wed-cyn-cir/fountain-flowers-front.png"
              className="flower-front"
              alt="flower"
            />
          </div>
          <CountDownPage />
          <BrideAndGroomPage />
          <VenuePage />
          <DressCodePage />
          <ReservationPage
            guestPlusOneAllowed={guestPlusOneAllowed}
            guestRsvpnSubmitted={guestRsvpnSubmitted}
            guestName={guestName}
            guestHonorific={guestHonorific}
            setGuestRsvpSubmitted={setGuestRsvpSubmitted}
          />
          <GiftPage />
          <GalleryPage />
          <AudioPlayer shouldPlay={isLandingClear}/>
        </>
      )}
    </div>
  );
}
